
export default function compute() {
   console.log(window.prices);

    $('.hideIfNoTotal,.hideIfNoAddressage,.hideIfNoEnvellopes,.hideIfNoPaper').addClass('d-none').removeClass('d-flex');

    if (typeof paperPrices[window.prices.chosen_paper_id][window.prices.paper_qty] !== 'undefined'){
        window.prices.total_paper=parseFloat(paperPrices[window.prices.chosen_paper_id][window.prices.paper_qty].prix);
    }

    /**
     * hideIfNoTotal
 hideIfNoAddressage
     hideIfNoEnvellopes
     hideIfNoPaper
     */
    if (window.prices.skip_enveloppe !== true){
        window.prices.total_enveloppes=(window.prices.enveloppe_unit_price*window.prices.enveloppe_qty).round(2);
    }else{
        window.prices.total_enveloppes=0;
    }

    if (window.prices.addressage_backdelivery !== window.prices.addressage_delivery){
        window.prices.total_addressage=window.prices.addressage_backdelivery> 0 ? window.prices.addressage_backdelivery:window.prices.addressage_delivery;
    }else{
        window.prices.total_addressage=0;
    }

    $('.dynamic[data-value="chosen_paper"]').text(window.prices.chosen_paper);
    $('.dynamic[data-value="paper_qty"]').text(window.prices.paper_qty);
    $('.dynamic[data-value="addressage_delivery"]').text(window.prices.addressage_delivery);
    $('.dynamic[data-value="addressage_backdelivery"]').text(window.prices.addressage_backdelivery);
    $('.dynamic[data-value="total_enveloppes"]').text(window.prices.total_enveloppes);
    $('.dynamic[data-value="total_addressage"]').text(window.prices.total_addressage);
    $('.dynamic[data-value="total_paper"]').text(window.prices.total_paper);
    $('.dynamic[data-value="total_tvac"]').text(window.prices.total_paper+window.prices.total_enveloppes+window.prices.total_addressage);

    if (typeof paperPrices[window.prices.chosen_paper_id][window.prices.paper_qty] !== 'undefined'){
        $('.hideIfNoPaper').removeClass('d-none').addClass('d-flex');
        $('.hideIfNoTotal').removeClass('d-none').addClass('d-flex');
    }else{
        $('.hideIfNoPaper').addClass('d-none').removeClass('d-flex');
        $('.hideIfNoTotal').addClass('d-none').removeClass('d-flex');
    }

    if (!window.prices.skip_enveloppe && window.prices.total_enveloppes >0 ){
        $('.hideIfNoEnvellopes').removeClass('d-none').addClass('d-flex');

    }else{
        $('.hideIfNoEnvellopes').addClass('d-none').removeClass('d-flex');
    }

    if (window.prices.round_corner){
        $('.hideIfNoRounded').removeClass('d-none').addClass('d-flex');
    }else{
        $('.hideIfNoRounded').addClass('d-none').removeClass('d-flex');
    }

    if (window.prices.addressage_delivery>0){
        $('.hideIfNoAddressage').removeClass('d-none').addClass('d-flex');
    }else if( window.prices.addressage_backdelivery<=0){
        $('.hideIfNoAddressage').addClass('d-none').removeClass('d-flex');
    }

    if( window.prices.addressage_backdelivery>0){
        $('.hideIfNoBackAddressage').removeClass('d-none').addClass('d-flex');
        $('.hideIfNoAddressage').removeClass('d-none').addClass('d-flex');
    }else{
        $('.hideIfNoBackAddressage').addClass('d-none').removeClass('d-flex');
    }
}
